import store from "@/store";

const stylingConfiguration = {
    Styling: {
        type: "section",
        children: {
            backgroundEnabled: {
                type: "boolean",
                default: false
            },
            backgroundColor: {
                type: "color",
                default: "#ffffff"
            },
            color: {
                type: "color",
                default: "#000000"
            },
            style: {
                type: "list",
                options: ["widgetstyle", "blockstyle", "tightstyle"],
                default: "widgetstyle"
            },
        }
    }
}

const updateConfiguration = {
    interval: {
        type: "numlist",
        options: ["1", "10", "20", "30", "60", "180", "360"],
        default: 180
    },
    animationDuration: {
        type: "numlist",
        options: ["1", "650", "1300"],
        default: 650
    }
}

const alignConfiguration = {
    verticalAlign: {
        type: "list",
        options: ["top", "middle", "bottom"],
        default: "middle"
    },
    horizontalAlign: {
        type: "list",
        options: ["left", "center", "right"],
        default: "center"
    }
}

const sourceConfiguration = (plug = {}, machineId = true) => {
    let out = {};
    if (machineId){
        out.machineId =  {
            type: "list",
            source: "machineList",
        };
    }
    return {
        ...out,
        dataSource: {
            type: "list",
            source: "dataSources",
            default: "machines",
        },
        ...plug,
        valueKey: {
            type: "list",
            source: "sourceKeys",
        },
    }
}

const sourceComputed = {
    activeQuery() {
        if (this.config.sqlSource) {
            return this.queryByRecordNo(this.config.sqlSource);
        }
        return null;
    },
    sqlParametersFromString() {
        let sqlParameters = [];
        if (this.activeQuery) {
            const regex = /(<%.*?%>)/g;
            sqlParameters = this.activeQuery.sql_query
                .match(regex)
                .filter((m) => m !== "<%company_id%>");
        }
        return sqlParameters;
    },
    parameterConfigOptions() {
        let extras = {};
        for (let i = 0; i < this.sqlParametersFromString.length; i++) {
            extras["param_" + i] = {
                label: this.sqlParametersFromString[i],
                type: "string",
            };
        }
        return extras;
    },
    parameters() {
        let params = {};
        for (let i = 0; i < this.sqlParametersFromString.length; i++) {
            params[this.sqlParametersFromString[i]] = this.config["param_" + i];
        }
        return params;
    },
    sqlQueryPlug() {
        if (this.config.dataSource == "customSqlQuery") {
            return {
                sqlSource: {
                    type: "list",
                    source: "sqlList",
                },
                interval: {
                    type: "list",
                    options: [20, 60, 120, 300, 600, 3600],
                },
                ...this.parameterConfigOptions,
            };
        }
        return {};
    },
    machine() {
        return this.machineById(this.config.machineId);
    },
    runningOrders() {
        return this.runningByMachine(this.config.machineId);
    },
    plannedOrders() {
        return this.plannedByMachine(this.config.machineId);
    },
    sources() {
        return {
            plannedOrders: !this.multiSource ? this.plannedOrders[0] || [] :  this.plannedOrders,
            runningOrders:!this.multiSource ?  this.runningOrders[0] || [] :  this.runningOrders,
            customSqlQuery: this.multiSource ? this.queryData[this.sqlSource] || [] : this.customSqlQuery || [],
            machines: !this.multiSource ? this.machine :this.machines,
        };
    },
    customSqlQuery() {
        return this.config?.sqlSource
            ? this.queryData[this.config?.sqlSource]?.filter((m) => {
                return (
                    m.object_no == this.config.machineId ||
                    m.machine_id == this.config.machineId
                );
            })
            : [] || [];
    },
    source() {
        return this.config.dataSource
            ? this.sources[this.config.dataSource]
            : this.machine || this.machine;
    },
    rawValue() {
        return this.config.valueKey
            ? this.source[this.config.valueKey]
            : this.config.valueKey;
    },
    sqlSource() {
        return this.config.sqlSource;
    },
}

const sourceState = {
    machines: (state) => state.machines.index,
    queryData: (state) => state.queries.data,
}
const sourceGetters = {
    machineById: "machines/byId",
    runningByMachine: "orders/runningByMachine",
    plannedByMachine: "orders/plannedByMachine",
    queryByRecordNo: "queries/byRecordNo",
}
const sourceWatchers = {
    sqlSource() {
        store.dispatch("queries/loadQueryData", {
            record_no: this.config.sqlSource,
            parameters: this.parameters,
        });
    },
}
export { stylingConfiguration, alignConfiguration, updateConfiguration, sourceConfiguration, sourceComputed, sourceGetters, sourceState, sourceWatchers }