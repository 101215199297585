<template>
  <select
    v-model.number="config[k]"
    class="form-control"
    v-if="num && sourceLoaded"
  >
    <option :value="o.value ? o.value : o" v-for="o in options" :key="o">
      {{ o.label ? o.label : o }}
    </option>
  </select>

  <select v-model="config[k]" class="form-control" v-else-if="sourceLoaded">
    <option :value="o.value ? o.value : o" v-for="o in options" :key="o">
      {{ o.label ? o.label : o }}
    </option>
  </select>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import store from "@/store";
export default {
  props: {
    config: {
      required: true,
    },
    val: {},
    k: {},
    num: {
      default: false,
    },
  },
  data() {
    return {
      sourceLoaded: true,
    };
  },
  created() {
    this.loadCurrentSource();
  },
  methods: {
    loadCurrentSource() {
      if (this.k === "dataSource" && this.machine_id) {
        console.warn("loading source!");
        //we should fetch data when we can
        this.sourceLoaded = false;
        let actions = {
          plannedOrders: "orders/loadPlannedOrdersForMachine",
          runningOrders: "orders/loadRunningOrderForMachine",
          machineList: "machines/loadMachine",
        };
        let action = actions[this.config.dataSource] || "machines/loadMachines";
        store.dispatch(action, { machine_id: this.machine_id }).then(() => {
          this.sourceLoaded = true;
        });
      }
      if (this.k === "sqlSource" || this.k === "ownOverviewSource") {
        console.warn("loading source!");
        //we should fetch data when we can
        this.sourceLoaded = false;
        store.dispatch("queries/loadQueryList").then(() => {
          this.sourceLoaded = true;
        });
      }
      if (this.k === "ownOverviewSource") {
        this.ownOverviewLoaded = false;
        store.dispatch("config/loadOwnOverviews").then(() => {
          this.ownOverviewLoaded = true;
        });
      }
    },
  },
  computed: {
    ...mapState({
      machines: (state) => state.machines.index,
      queries: (state) => state.queries.list,
      queryData: (state) => state.queries.data,
      overviews: (state) => state.config.ownOverviews,
    }),
    ...mapGetters({
      runningByMachine: "orders/runningByMachine",
      plannedByMachine: "orders/plannedByMachine",
    }),
    runningOrders() {
      if (this.k.includes('valueKey') && this.machine_id) {
        return this.runningByMachine(this.machine_id);
      }
      return [];
    },
    plannedOrders() {
      if (this.k.includes('valueKey') && this.machine_id) {
        return this.plannedByMachine(this.machine_id);
      }
      return [];
    },
    sources() {
      return {
        machineList: this.machineList,
        sqlList: this.sqlList,
        ownOverviewList: this.ownOverviewList,
        machineKeys: this.machineKeys,
        //customizable sources
        plannedOrders: this.plannedOrders,
        runningOrders: this.runningOrders,
        machines: this.machines,
        customSqlQuery: this.customSqlQuery,
        sourceKeys: this.sourceKeys,
        sqlKeys: this.sqlKeys,
        //
        locationtypeList: this.locationtypeList,
        locationList: this.locationList,
        dataSources: this.dataSources,
      };
    },
    dataSources() {
      let sources = [];
      if (this.machine_id) {
        sources.push({
          label: "machines",
          value: "machines",
        });
        sources.push({
          label: "plannedOrders",
          value: "plannedOrders",
        });
        sources.push({
          label: "runningOrders",
          value: "runningOrders",
        });
      }
      sources.push({
        label: "customSqlQuery",
        value: "customSqlQuery",
      });
      return sources;
    },
    machineList() {
      return this.machines.map((m) => m.machine_id).sort();
    },
    sqlList() {
      return this.queries;
    },
    ownOverviewList() {
      return this.overviews.map((o) => {
        return { ...o, label: o.overview_name };
      });
    },
    customSqlQuery() {
      if (this.k.includes('valueKey')) {
        //console.log(Object.keys(this.queryData[this.config?.sqlSource]?.[0] || {}));
        return this.queryData[this.config?.sqlSource] || [];
        return this.plannedByMachine(this.machine_id);
      }
      return [];
    },
    machineKeys() {
      return Object.keys(this.machines[0]).sort();
    },
    source() {
      return this.config?.dataSource && this?.sources
        ? this?.sources[this.config?.dataSource]
        : [] || [];
    },
    sourceKeys() {
      return this.source.length ? Object.keys(this.source[0]).sort() : [];
    },
    sqlKeys() {
      return this.config?.sqlSource && this.queryData?.[this.config.sqlSource]
        ? Object.keys(this.queryData[this.config.sqlSource]?.[0]).sort()
        : [];
    },
    locationtypeList() {
      return ["department", "zone", "category"];
    },
    locationList() {
      return this.config.locationtype
        ? this["available" + this.config.locationtype]
        : null;
    },
    availabledepartment() {
      let uniqueKeys = [
        ...new Set(this.machines.map((item) => item.department)),
      ];
      let unique = uniqueKeys.map((k) => {
        return {
          value: k,
          label:
            k +
            ": " +
            this.machines.find((o) => o.department === k)
              .department_description,
        };
      });
      return unique.filter((department) => {
        return department.value !== undefined && department.value != "";
      });
    },
    availablezone() {
      let uniqueKeys = [...new Set(this.machines.map((item) => item.zone))];
      let unique = uniqueKeys.map((k) => {
        return {
          value: k,
          label: k + ": " + this.machines.find((o) => o.zone === k).zone_desc,
        };
      });
      return unique.filter((zone) => {
        return zone.value !== undefined && zone.value != "";
      });
    },
    availablecategory() {
      let uniqueKeys = [...new Set(this.machines.map((item) => item.category))];
      let unique = uniqueKeys.map((k) => {
        return {
          value: k,
          label:
            k + ": " + this.machines.find((o) => o.category === k).cat_desc,
        };
      });
      return unique.filter((category) => {
        return category.value !== undefined && category.value != "";
      });
    },
    options() {
      if (this.val.source) {
        return this.sources[this.val.source];
      } else {
        return this.filteredOptions;
      }
    },
    filteredOptions() {
      const options = [];
      if (this.val.options) {
        this.val.options.forEach((o) => {
          if (typeof o === "string" || typeof o === "number") {
            options.push(o);
          } else if (typeof o === "object") {
            let needsMet = true;
            let needs = Object.keys(o.needs);
            needs.forEach((key) => {
              let req = o.needs[key];
              //console.warn(req, key, this.config[key]);
              if (!req.includes(this.config[key])) {
                needsMet = false;
              }
            });
            if (needsMet) {
              options.push(o.option);
            }
          }
        });
      }
      return options;
    },
    machine_id() {
      return this.config.machineId;
    },
    dataSource() {
      return this.config.dataSource;
    },
    optionsLength() {
      //console.warn("OPTIONS >>",this.options,this.k,this.config)
      return this.options && this.options.length ? this.options.length : 0;
    },
  },
  watch: {
    optionsLength(v) {
      //console.warn("LOOK>",v);
      if (v === 0) {
        this.config[this.k] = null;
      } else if (v === 1) {
        this.config[this.k] = this.options[0].value
          ? this.options[0].value
          : this.options[0];
      } else {
        let index = this.options.findIndex((o) => {
          return o == this.config[this.k] || o.value == this.config[this.k];
        });
        if (index === -1) {
          this.config[this.k] = null;
        }
      }
    },
    dataSource() {
      this.loadCurrentSource();
    },
    sqlSource() {
      this.loadCurrentSource();
    },
    machine_id() {
      this.loadCurrentSource();
    },
  },
};
</script>

<style>
</style>