<template>
  <div class="configurator" style="background: white; color: black">
    <div class="config-option" v-for="(val, key) in options" :key="key">
      {{ val.label ? val.label : key }}:
      <section class="dashboard-section" v-if="val.type === 'section'">
        <configurator :config="config" :options="val.children"></configurator>
      </section>
      <div class="list" v-if="val.type === 'list'">
        <configurator-select :k="key" :val="val" :config="config" />
      </div>
      <div class="list" v-if="val.type === 'numlist'">
        <configurator-select :k="key" :val="val" :config="config" :num="true" />
      </div>
      <div class="list" v-if="val.type === 'text'">
        <textarea
          :name="key"
          :id="key"
          cols="30"
          rows="10"
          class="form-control"
          v-model="config[key]"
          :placeholder="config.placeholder"
        ></textarea>
      </div>
      <div class="list" v-if="val.type === 'string'">
        <input
          :name="key"
          :id="key"
          class="form-control"
          v-model="config[key]"
          type="text"
          :placeholder="config.placeholder"
        />
      </div>
      <div class="list" v-if="val.type === 'color'">
        <input
          type="color"
          :name="key"
          :id="key"
          v-model="config[key]"
          class="form-control"
        />
      </div>
      <div class="list" v-if="val.type === 'number'">
        <input
          type="number"
          :name="key"
          :id="key"
          v-model.number="config[key]"
          class="form-control"
        />
      </div>
      <div class="list" v-if="val.type === 'boolean'">
        <b-check
          type="color"
          :name="key"
          :id="key"
          v-model="config[key]"
          class="form-control"
        />
      </div>
    </div>
    <div class="save-load-config" v-if="name">
      <br/>
      <p>Save/load specific widget config:</p>
      <input type="text" v-model="configname" />
      <button @click="save">save</button><br/>
      <select name="" v-model="selectedConfig">
        <option :value="null"></option>
        <option :value="c.config" v-for="c in savedConfigs" :key="c.name">
          {{ c.name }}
        </option>
      </select>
      <button @click="load">load</button>
    </div>
  </div>
</template>

<script>
import ConfiguratorSelect from "./ConfiguratorSelect.vue";
import { mapState } from "vuex";
import _ from "lodash";
import BCheck from "../b-check.vue";
import store from "@/store";
export default {
  data() {
    return {
      configname: "",
      selectedConfig: null,
    };
  },
  mounted() {
    this.selectedConfig = null;

    // fill in unfilled entries
    for (const [key, value] of Object.entries(this.options)) {
      if (value.type !== "section" && this.config[key] === undefined) {
        this.config[key] = value.default || null;
      }
    }
    store.dispatch("configurator/loadConfigs", this.name);
  },
  props: {
    options: {},
    config: {},
    name: {},
  },
  methods: {
    save() {
      store
        .dispatch("configurator/saveConfig", {
          compname: this.name,
          name: this.configname,
          config: this.config,
        })
        .then(() => {
          store.dispatch("configurator/loadConfigs", this.name);
        });
      this.configname = "";
    },
    load() {
      if (this.selectedConfig !== null) {
        let keys = Object.keys(this.selectedConfig);
        for (let i = 0; i < keys.length; i++) {
          let val = this.selectedConfig[keys[i]];
          this.config[keys[i]] = val;
        }
      }
    },
  },
  computed: {
    ...mapState({
      configuratorConfigs: (state) => state.configurator.index,
    }),
    savedConfigs() {
      return this.configuratorConfigs[this.name] || [];
    },
  },
  emits: ["configuring"],
  components: {
    ConfiguratorSelect,
    BCheck,
  },
};
</script>

<style>
</style>