<template>
  <div class="prod-stack-graph">
    <configurator
      v-if="mode === 'configuring'"
      :name="name"
      :options="configOptions"
      :config="config"
    />
    <div v-else-if="avData" class="scrap-5061-wrapper">
      <div class="scrap-5061-graph">
        <div class="y-axis">
          <div class="ticks">
            <div
              class="tick"
              v-for="tick in ticks"
              :key="tick.label"
              :style="{
                bottom: tick.pos + '%',
                color: config.tickColor || 'black',
              }"
            >
              {{ tick.label }} -
            </div>
          </div>
          <div class="spacer">
            <v-html
              :tag="config.xLabels || 'h2'"
              :color="config.color"
              text="&nbsp;"
            />
          </div>
        </div>
        <div class="machine-column-wrapper">
          <div class="fades" v-if="config.backgroundEnabled">
            <div
              class="columns"
              :style="{ background: config.backgroundColor }"
            >
              <div
                class="fade"
                v-for="tick in ticks"
                :key="tick.label"
                :style="{
                  bottom: tick.pos + '%',
                  height: tick.h + '%',
                }"
              ></div>
            </div>
            <div class="spacer">
              <v-html
                :tag="config.xLabels || 'h2'"
                :color="config.color"
                text="&nbsp;"
              />
            </div>
          </div>
          <div
            class="machine-column"
            v-for="(dGroup, x) in dataGrouped"
            :key="'x-' + x"
          >
            <div class="columns">
              <div class="plot-column">
                <div class="columns">
                  <div class="stack-total" v-if="config.stackTotals">
                    <div>
                      <v-html
                        :tag="config.stackLabels || 'p'"
                        :color="config.stackColor"
                        :text="
                          dGroup.reduce((a, b) => {
                            return a + b?.[this.config.yKey];
                          }, 0)
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="scrap-stack indicator"
                    v-for="yVal in dGroup"
                    :key="'group-' + yVal"
                    :class="
                      'c-' +
                      yVal?.[this.config?.colorKey || 'color']?.toLowerCase()
                    "
                    :style="{
                      'flex-basis':
                        (yVal?.[this.config.yKey] / max) * usedHeight + '%',
                    }"
                  >
                    <div class="hover-indicator">
                      <div class="value" v-if="config.showLabels">
                        {{ yVal?.[this.config.yKey] }}
                        <div
                          class="tt"
                          v-if="config.showTooltips"
                          style="color: black"
                        >
                          {{ yVal?.[this.config.groupKey] }}<br />{{
                            this.config.yKey
                          }}:
                          {{ yVal?.[this.config.yKey] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="labels">
                  <v-html
                    :tag="config.xLabels || 'h2'"
                    :color="config.tickColor || 'black'"
                    style="overflow-x: hidden; white-space: nowrap"
                    :text="trimLabel(x)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="machine-column"
            v-for="i in config.emptyRows"
            :key="'empty-' + i"
          ></div>
        </div>
      </div>
      <div
        class="legend"
        :style="{
          color: config.color || 'black',
          background: config.legendColor || '#eeeeee',
        }"
        :class="config.legendPosition"
        v-if="
          config.showLegend &&
          (config.legendPosition == 'bottom' || config.legendPosition == 'top')
        "
      >
        <div class="legend-item" v-for="item in legendItems" :key="item.key">
          <div
            class="indicator legend-colorbox"
            :class="'c-' + item.color?.toLowerCase()"
          ></div>
          <div class="legend-label">
            <v-html
              :tag="config.legendLabels || 'h5'"
              :color="config.color"
              :text="
                config.legendSums
                  ? item.sum + ' ' + item.key || '-'
                  : item.key || ''
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="legend"
      :style="{
        color: config.color || 'black',
        background: config.legendColor || '#eeeeee',
      }"
      :class="config.legendPosition"
      v-if="
        mode !== 'configuring' &&
        config.showLegend &&
        (config.legendPosition == 'left' || config.legendPosition == 'right')
      "
    >
      <div class="legend-item" v-for="item in legendItems" :key="item.key">
        <div
          class="indicator legend-colorbox"
          :class="'c-' + item.color?.toLowerCase()"
        ></div>
        <div class="legend-label">
          <v-html
            :tag="config.legendLabels || 'h5'"
            :color="config.color"
            :text="
              config.legendSums
                ? item.sum + ' ' + item.key || '-'
                : item.key || ''
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let fakeLoadedData = [
  {
    machine_id: "A1",
    hour: "2022-09-21 23",
    value: 390,
    value2: 490,
    color: "green",
  },
  {
    machine_id: "A1",
    hour: "2022-09-22 00",
    value: 395,
    value2: 390,
    color: "green",
  },
  {
    machine_id: "A1",
    hour: "2022-09-22 01",
    value: 400,
    value2: 390,
    color: "green",
  },
  {
    machine_id: "A11",
    hour: "2022-09-22 01",
    value: 400,
    value2: 390,
    color: "green",
  },
  {
    machine_id: "A12",
    hour: "2022-09-22 01",
    value: 400,
    value2: 390,
    color: "green",
  },
  {
    machine_id: "A13",
    hour: "2022-09-22 01",
    value: 400,
    value2: 390,
    color: "orange",
  },
  {
    machine_id: "A1",
    hour: "2022-09-22 02",
    value: 90,
    value2: 390,
    color: "green",
  },
  {
    machine_id: "A2",
    hour: "2022-09-22 00",
    value: 5,
    value2: 390,
    color: "red",
  },
  {
    machine_id: "A2",
    hour: "2022-09-21 23",
    value: 39,
    value2: 390,
    color: "red",
  },
  {
    machine_id: "A2",
    hour: "2022-09-22 02",
    value: 390,
    value2: 390,
    color: "red",
  },
  {
    machine_id: "A3",
    hour: "2022-09-22 01",
    value: 40,
    value2: 390,
    color: "orange",
  },
  {
    machine_id: "A3",
    hour: "2022-09-22 02",
    value: 40,
    value2: 390,
    color: "orange",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 02",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 03",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 04",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 05",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 06",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 07",
    value: 450,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 08",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 09",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 10",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 11",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 12",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 13",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 14",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 15",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 16",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 17",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 18",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 19",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 20",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 21",
    value: 400,
    value2: 390,
    color: "blue",
  },
  {
    machine_id: "A4",
    hour: "2022-09-22 22",
    value: 400,
    value2: 390,
    color: "blue",
  },
];
import PropGraph from "@/components/PropGraph.vue";
import {
  alignConfiguration,
  stylingConfiguration,
} from "@/helpers/configurator";
import Configurator from "../Configurator";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import _ from "lodash";
export default {
  props: {
    mode: {
      default: "active", // "configuring", "previewing"
    },
    config: {},
    name: {},
  },
  data() {
    return {
      loadingFor: null,
      loaded: false,
      loadedData: null,
      interval: null,
      usedHeight: 95,
    };
  },
  mounted() {
    this.loadData();
    this.startInterval();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapGetters({
      machineById: "machines/byId",
      queryByRecordNo: "queries/byRecordNo",
    }),
    configOptions() {
      return {
        ...stylingConfiguration,
        tickColor: {
          type: "color",
          default: "#000000",
        },
        sqlSource: {
          type: "list",
          source: "sqlList",
        },
        xKey: {
          type: "string",
          default: "hour",
        },
        yKey: {
          type: "string",
          default: "value",
        },
        colorKey: {
          type: "string",
          default: "color",
        },
        groupKey: {
          type: "string",
          default: "machine_id",
        },
        /*
        groupKeyFilter: {
          type: "text",
        },
        */
        labelLength: {
          type: "number",
          default: "-1",
        },
        labelTrimMethod: {
          type: "list",
          options: ["left", "right"],
          default: "right",
        },
        cInterval: {
          type: "list",
          options: [-1, 10, 30, 60, 300],
          default: 60,
        },
        xLabels: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p"],
          default: "p",
        },
        showTooltips: {
          type: "boolean",
          default: true,
        },
        showLabels: {
          type: "boolean",
          default: true,
        },
        showLegend: {
          type: "boolean",
          default: true,
        },
        stackColor: {
          type: "color",
          default: "#000000",
        },
        stackLabels: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p"],
          default: "p",
        },
        stackTotals: {
          type: "boolean",
          default: true,
        },
        legendSums: {
          type: "boolean",
          default: true,
        },
        legendColor: {
          type: "color",
          default: "#eeeeee",
        },
        legendPosition: {
          type: "list",
          options: ["left", "right", "top", "bottom"],
          default: "top",
        },
        /*
        graphSpecificLegend: {
          type: "boolean",
          default: true,
        },
        */
        legendLabels: {
          type: "list",
          options: ["h1", "h2", "h3", "h4", "h5", "p"],
          default: "h5",
        },
        ticks: {
          type: "number",
          default: -1,
        },
        emptyRows: {
          type: "number",
          default: 0,
        },
      };
    },
    ticks() {
      let numTicks = this.config.ticks || 5;
      let maxDigits = ("" + Math.round(this.max / numTicks)).length - 1;
      let roundingFactor = Math.pow(10, maxDigits);
      let step =
        Math.floor(this.max / numTicks / roundingFactor) * roundingFactor;
      console.log(roundingFactor);
      numTicks = Math.floor(this.max / step);
      let ticks = [];
      for (let i = 0; i <= numTicks + 1; i++) {
        if (
          ((i * step) / this.max) * this.usedHeight <
          Math.min(this.usedHeight + 3, 100)
        ) {
          ticks.push({
            label: i * step,
            pos: ((i * step) / this.max) * this.usedHeight,
            h: (step / this.max) * this.usedHeight,
          });
        }
      }
      return ticks;
    },
    xs() {
      if (this.config.xKey?.length > 0 && this.avData) {
        return [...new Set(this.avData.map((d) => d[this.config.xKey]))];
      }
      return ["00", "01"];
    },
    groups() {
      if (this.config.groupKey?.length > 0 && this.avData) {
        return [...new Set(this.avData.map((d) => d[this.config.groupKey]))];
      }
      return ["1", "2"];
    },
    /*
    xLabels() {
      let labelArray = [];
      if (this.config.shiftLabelList?.length > 0) {
        labelArray = this.config.shiftLabelList.split(",");
      }
      let map = {};
      this.shifts.forEach((s, i) => {
        map[s] = labelArray[i] || s;
      });
      return map;
    },
    */
    activeQuery() {
      if (this.config.sqlSource) {
        return this.queryByRecordNo(this.config.sqlSource);
      }
      return null;
    },
    avData() {
      return _.orderBy(
        this.loadedData || fakeLoadedData,
        [this.config.xKey],
        ["asc"]
      );
    },
    dataGrouped() {
      let allData = {};
      this.xs.forEach((x) => {
        allData[x] = [];
        this.groups.forEach((g) => {
          allData[x].push(
            ...this.avData.filter(
              (d) => d[this.config.xKey] == x && d[this.config.groupKey] == g
            )
          );
        });
      });
      return allData;
    },
    max() {
      let groups = _.groupBy(this.avData, this.config?.xKey);
      let max = 0;
      _.forEach(this.dataGrouped, (group, x) => {
        console.log(group, x);
        let sum = _.sumBy(group, (o) => {
          return +o?.[this.config?.yKey] || 0;
        });
        if (sum > max) {
          max = sum;
        }
      });
      return max;
    },
    legendItems() {
      let posts = this.avData;
      return _.map(_.groupBy(posts, this.config?.groupKey), (group, key) => {
        return {
          sum: _.sumBy(group, (i) => i[this.config?.yKey]),
          key,
          color: group?.[0]?.[this.config?.colorKey] || "gray",
        };
      });
    },
  },
  methods: {
    loadData() {
      //console.warn("loading data...");
      if (this.config.sqlSource && this.loadingFor != this.config.sqlSource) {
        this.fetchData();
      } else {
        if (!this.loaded) {
          store.dispatch("queries/loadQueryList");
          this.loaded = true;
        }
        setTimeout(this.loadData, 500);
      }
    },
    intervalFetch() {
      if (this.loadingFor === null && this.config.sqlSource) {
        this.fetchData();
      }
    },
    fetchData() {
      this.loadingFor = this.config.sqlSource;
      store
        .dispatch("queries/loadQueryData", {
          record_no: this.config.sqlSource,
          parameters: {},
        })
        .then((data) => {
          this.loadingFor = null;
          this.loadedData = data;
        });
    },
    startInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      if (this.config.cInterval && this.config.cInterval > 5) {
        this.interval = setInterval(
          this.intervalFetch,
          this.config.cInterval * 1000
        );
      }
    },
    trimLabel(x) {
      if (this.config?.labelLength != "-1") {
        if (this.config?.labelTrimMethod == "right") {
          return x.slice(-this.config?.labelLength);
        }
        if (this.config?.labelTrimMethod == "left") {
          return x.slice(0, this.config?.labelLength);
        }
      }
      return x;
    },
  },
  watch: {
    sqlSource() {
      this.loadData();
    },
  },
  components: {
    PropGraph,
    Configurator,
  },
};
</script>

<style lang="scss">
</style>